export const COUNTRY_ALPHA_CODES = [
    { value: 'Afghanistan', label: 'AF', alpha_three: 'AFG', numeric: '004' },
    { value: 'Albania', label: 'AL', alpha_three: 'ALB', numeric: '008' },
    { value: 'Algeria', label: 'DZ', alpha_three: 'DZA', numeric: '012' },
    { value: 'American Samoa', label: 'AS', alpha_three: 'ASM', numeric: '016' },
    { value: 'Andorra', label: 'AD', alpha_three: 'AND', numeric: '020' },
    { value: 'Angola', label: 'AO', alpha_three: 'AGO', numeric: '024' },
    { value: 'Anguilla', label: 'AI', alpha_three: 'AIA', numeric: '660' },
    { value: 'Antarctica', label: 'AQ', alpha_three: 'ATA', numeric: '010' },
    {
      value: 'Antigua and Barbuda',
      label: 'AG',
      alpha_three: 'ATG',
      numeric: '028',
    },
    { value: 'Argentina', label: 'AR', alpha_three: 'ARG', numeric: '032' },
    { value: 'Armenia', label: 'AM', alpha_three: 'ARM', numeric: '051' },
    { value: 'Aruba', label: 'AW', alpha_three: 'ABW', numeric: '533' },
    { value: 'Australia', label: 'AU', alpha_three: 'AUS', numeric: '036' },
    { value: 'Austria', label: 'AT', alpha_three: 'AUT', numeric: '040' },
    { value: 'Azerbaijan', label: 'AZ', alpha_three: 'AZE', numeric: '031' },
    { value: 'Bahamas (the)', label: 'BS', alpha_three: 'BHS', numeric: '044' },
    { value: 'Bahrain', label: 'BH', alpha_three: 'BHR', numeric: '048' },
    { value: 'Bangladesh', label: 'BD', alpha_three: 'BGD', numeric: '050' },
    { value: 'Barbados', label: 'BB', alpha_three: 'BRB', numeric: '052' },
    { value: 'Belarus', label: 'BY', alpha_three: 'BLR', numeric: '112' },
    { value: 'Belgium', label: 'BE', alpha_three: 'BEL', numeric: '056' },
    { value: 'Belize', label: 'BZ', alpha_three: 'BLZ', numeric: '084' },
    { value: 'Benin', label: 'BJ', alpha_three: 'BEN', numeric: '204' },
    { value: 'Bermuda', label: 'BM', alpha_three: 'BMU', numeric: '060' },
    { value: 'Bhutan', label: 'BT', alpha_three: 'BTN', numeric: '064' },
    {
      value: 'Bolivia (Plurinational State of)',
      label: 'BO',
      alpha_three: 'BOL',
      numeric: '068',
    },
    {
      value: 'Bonaire, Sint Eustatius and Saba',
      label: 'BQ',
      alpha_three: 'BES',
      numeric: '535',
    },
    {
      value: 'Bosnia and Herzegovina',
      label: 'BA',
      alpha_three: 'BIH',
      numeric: '070',
    },
    { value: 'Botswana', label: 'BW', alpha_three: 'BWA', numeric: '072' },
    { value: 'Bouvet Island', label: 'BV', alpha_three: 'BVT', numeric: '074' },
    { value: 'Brazil', label: 'BR', alpha_three: 'BRA', numeric: '076' },
    {
      value: 'British Indian Ocean Territory (the)',
      label: 'IO',
      alpha_three: 'IOT',
      numeric: '086',
    },
    {
      value: 'Brunei Darussalam',
      label: 'BN',
      alpha_three: 'BRN',
      numeric: '096',
    },
    { value: 'Bulgaria', label: 'BG', alpha_three: 'BGR', numeric: '100' },
    { value: 'Burkina Faso', label: 'BF', alpha_three: 'BFA', numeric: '854' },
    { value: 'Burundi', label: 'BI', alpha_three: 'BDI', numeric: '108' },
    { value: 'Cabo Verde', label: 'CV', alpha_three: 'CPV', numeric: '132' },
    { value: 'Cambodia', label: 'KH', alpha_three: 'KHM', numeric: '116' },
    { value: 'Cameroon', label: 'CM', alpha_three: 'CMR', numeric: '120' },
    { value: 'Canada', label: 'CA', alpha_three: 'CAN', numeric: '124' },
    {
      value: 'Cayman Islands (the)',
      label: 'KY',
      alpha_three: 'CYM',
      numeric: '136',
    },
    {
      value: 'Central African Republic (the)',
      label: 'CF',
      alpha_three: 'CAF',
      numeric: '140',
    },
    { value: 'Chad', label: 'TD', alpha_three: 'TCD', numeric: '148' },
    { value: 'Chile', label: 'CL', alpha_three: 'CHL', numeric: '152' },
    { value: 'China', label: 'CN', alpha_three: 'CHN', numeric: '156' },
    {
      value: 'Christmas Island',
      label: 'CX',
      alpha_three: 'CXR',
      numeric: '162',
    },
    {
      value: 'Cocos (Keeling) Islands (the)',
      label: 'CC',
      alpha_three: 'CCK',
      numeric: '166',
    },
    { value: 'Colombia', label: 'CO', alpha_three: 'COL', numeric: '170' },
    { value: 'Comoros (the)', label: 'KM', alpha_three: 'COM', numeric: '174' },
    {
      value: 'Congo (the Democratic Republic of the)',
      label: 'CD',
      alpha_three: 'COD',
      numeric: '180',
    },
    { value: 'Congo (the)', label: 'CG', alpha_three: 'COG', numeric: '178' },
    {
      value: 'Cook Islands (the)',
      label: 'CK',
      alpha_three: 'COK',
      numeric: '184',
    },
    { value: 'Costa Rica', label: 'CR', alpha_three: 'CRI', numeric: '188' },
    { value: 'Croatia', label: 'HR', alpha_three: 'HRV', numeric: '191' },
    { value: 'Cuba', label: 'CU', alpha_three: 'CUB', numeric: '192' },
    { value: 'Curaçao', label: 'CW', alpha_three: 'CUW', numeric: '531' },
    { value: 'Cyprus', label: 'CY', alpha_three: 'CYP', numeric: '196' },
    { value: 'Czechia', label: 'CZ', alpha_three: 'CZE', numeric: '203' },
    { value: "Côte d'Ivoire", label: 'CI', alpha_three: 'CIV', numeric: '384' },
    { value: 'Denmark', label: 'DK', alpha_three: 'DNK', numeric: '208' },
    { value: 'Djibouti', label: 'DJ', alpha_three: 'DJI', numeric: '262' },
    { value: 'Dominica', label: 'DM', alpha_three: 'DMA', numeric: '212' },
    {
      value: 'Dominican Republic (the)',
      label: 'DO',
      alpha_three: 'DOM',
      numeric: '214',
    },
    { value: 'Ecuador', label: 'EC', alpha_three: 'ECU', numeric: '218' },
    { value: 'Egypt', label: 'EG', alpha_three: 'EGY', numeric: '818' },
    { value: 'El Salvador', label: 'SV', alpha_three: 'SLV', numeric: '222' },
    {
      value: 'Equatorial Guinea',
      label: 'GQ',
      alpha_three: 'GNQ',
      numeric: '226',
    },
    { value: 'Eritrea', label: 'ER', alpha_three: 'ERI', numeric: '232' },
    { value: 'Estonia', label: 'EE', alpha_three: 'EST', numeric: '233' },
    { value: 'Eswatini', label: 'SZ', alpha_three: 'SWZ', numeric: '748' },
    { value: 'Ethiopia', label: 'ET', alpha_three: 'ETH', numeric: '231' },
    {
      value: 'Falkland Islands (the) [Malvinas]',
      label: 'FK',
      alpha_three: 'FLK',
      numeric: '238',
    },
    {
      value: 'Faroe Islands (the)',
      label: 'FO',
      alpha_three: 'FRO',
      numeric: '234',
    },
    { value: 'Fiji', label: 'FJ', alpha_three: 'FJI', numeric: '242' },
    { value: 'Finland', label: 'FI', alpha_three: 'FIN', numeric: '246' },
    { value: 'France', label: 'FR', alpha_three: 'FRA', numeric: '250' },
    { value: 'French Guiana', label: 'GF', alpha_three: 'GUF', numeric: '254' },
    {
      value: 'French Polynesia',
      label: 'PF',
      alpha_three: 'PYF',
      numeric: '258',
    },
    {
      value: 'French Southern Territories (the)',
      label: 'TF',
      alpha_three: 'ATF',
      numeric: '260',
    },
    { value: 'Gabon', label: 'GA', alpha_three: 'GAB', numeric: '266' },
    { value: 'Gambia (the)', label: 'GM', alpha_three: 'GMB', numeric: '270' },
    { value: 'Georgia', label: 'GE', alpha_three: 'GEO', numeric: '268' },
    { value: 'Germany', label: 'DE', alpha_three: 'DEU', numeric: '276' },
    { value: 'Ghana', label: 'GH', alpha_three: 'GHA', numeric: '288' },
    { value: 'Gibraltar', label: 'GI', alpha_three: 'GIB', numeric: '292' },
    { value: 'Greece', label: 'GR', alpha_three: 'GRC', numeric: '300' },
    { value: 'Greenland', label: 'GL', alpha_three: 'GRL', numeric: '304' },
    { value: 'Grenada', label: 'GD', alpha_three: 'GRD', numeric: '308' },
    { value: 'Guadeloupe', label: 'GP', alpha_three: 'GLP', numeric: '312' },
    { value: 'Guam', label: 'GU', alpha_three: 'GUM', numeric: '316' },
    { value: 'Guatemala', label: 'GT', alpha_three: 'GTM', numeric: '320' },
    { value: 'Guernsey', label: 'GG', alpha_three: 'GGY', numeric: '831' },
    { value: 'Guinea', label: 'GN', alpha_three: 'GIN', numeric: '324' },
    { value: 'Guinea-Bissau', label: 'GW', alpha_three: 'GNB', numeric: '624' },
    { value: 'Guyana', label: 'GY', alpha_three: 'GUY', numeric: '328' },
    { value: 'Haiti', label: 'HT', alpha_three: 'HTI', numeric: '332' },
    {
      value: 'Heard Island and McDonald Islands',
      label: 'HM',
      alpha_three: 'HMD',
      numeric: '334',
    },
    { value: 'Holy See (the)', label: 'VA', alpha_three: 'VAT', numeric: '336' },
    { value: 'Honduras', label: 'HN', alpha_three: 'HND', numeric: '340' },
    { value: 'Hong Kong', label: 'HK', alpha_three: 'HKG', numeric: '344' },
    { value: 'Hungary', label: 'HU', alpha_three: 'HUN', numeric: '348' },
    { value: 'Iceland', label: 'IS', alpha_three: 'ISL', numeric: '352' },
    { value: 'India', label: 'IN', alpha_three: 'IND', numeric: '356' },
    { value: 'Indonesia', label: 'ID', alpha_three: 'IDN', numeric: '360' },
    {
      value: 'Iran (Islamic Republic of)',
      label: 'IR',
      alpha_three: 'IRN',
      numeric: '364',
    },
    { value: 'Iraq', label: 'IQ', alpha_three: 'IRQ', numeric: '368' },
    { value: 'Ireland', label: 'IE', alpha_three: 'IRL', numeric: '372' },
    { value: 'Isle of Man', label: 'IM', alpha_three: 'IMN', numeric: '833' },
    { value: 'Israel', label: 'IL', alpha_three: 'ISR', numeric: '376' },
    { value: 'Italy', label: 'IT', alpha_three: 'ITA', numeric: '380' },
    { value: 'Jamaica', label: 'JM', alpha_three: 'JAM', numeric: '388' },
    { value: 'Japan', label: 'JP', alpha_three: 'JPN', numeric: '392' },
    { value: 'Jersey', label: 'JE', alpha_three: 'JEY', numeric: '832' },
    { value: 'Jordan', label: 'JO', alpha_three: 'JOR', numeric: '400' },
    { value: 'Kazakhstan', label: 'KZ', alpha_three: 'KAZ', numeric: '398' },
    { value: 'Kenya', label: 'KE', alpha_three: 'KEN', numeric: '404' },
    { value: 'Kiribati', label: 'KI', alpha_three: 'KIR', numeric: '296' },
    {
      value: "Korea (the Democratic People's Republic of)",
      label: 'KP',
      alpha_three: 'PRK',
      numeric: '408',
    },
    {
      value: 'Korea (the Republic of)',
      label: 'KR',
      alpha_three: 'KOR',
      numeric: '410',
    },
    { value: 'Kuwait', label: 'KW', alpha_three: 'KWT', numeric: '414' },
    { value: 'Kyrgyzstan', label: 'KG', alpha_three: 'KGZ', numeric: '417' },
    {
      value: "Lao People's Democratic Republic (the)",
      label: 'LA',
      alpha_three: 'LAO',
      numeric: '418',
    },
    { value: 'Latvia', label: 'LV', alpha_three: 'LVA', numeric: '428' },
    { value: 'Lebanon', label: 'LB', alpha_three: 'LBN', numeric: '422' },
    { value: 'Lesotho', label: 'LS', alpha_three: 'LSO', numeric: '426' },
    { value: 'Liberia', label: 'LR', alpha_three: 'LBR', numeric: '430' },
    { value: 'Libya', label: 'LY', alpha_three: 'LBY', numeric: '434' },
    { value: 'Liechtenstein', label: 'LI', alpha_three: 'LIE', numeric: '438' },
    { value: 'Lithuania', label: 'LT', alpha_three: 'LTU', numeric: '440' },
    { value: 'Luxembourg', label: 'LU', alpha_three: 'LUX', numeric: '442' },
    { value: 'Macao', label: 'MO', alpha_three: 'MAC', numeric: '446' },
    { value: 'Madagascar', label: 'MG', alpha_three: 'MDG', numeric: '450' },
    { value: 'Malawi', label: 'MW', alpha_three: 'MWI', numeric: '454' },
    { value: 'Malaysia', label: 'MY', alpha_three: 'MYS', numeric: '458' },
    { value: 'Maldives', label: 'MV', alpha_three: 'MDV', numeric: '462' },
    { value: 'Mali', label: 'ML', alpha_three: 'MLI', numeric: '466' },
    { value: 'Malta', label: 'MT', alpha_three: 'MLT', numeric: '470' },
    {
      value: 'Marshall Islands (the)',
      label: 'MH',
      alpha_three: 'MHL',
      numeric: '584',
    },
    { value: 'Martinique', label: 'MQ', alpha_three: 'MTQ', numeric: '474' },
    { value: 'Mauritania', label: 'MR', alpha_three: 'MRT', numeric: '478' },
    { value: 'Mauritius', label: 'MU', alpha_three: 'MUS', numeric: '480' },
    { value: 'Mayotte', label: 'YT', alpha_three: 'MYT', numeric: '175' },
    { value: 'Mexico', label: 'MX', alpha_three: 'MEX', numeric: '484' },
    {
      value: 'Micronesia (Federated States of)',
      label: 'FM',
      alpha_three: 'FSM',
      numeric: '583',
    },
    {
      value: 'Moldova (the Republic of)',
      label: 'MD',
      alpha_three: 'MDA',
      numeric: '498',
    },
    { value: 'Monaco', label: 'MC', alpha_three: 'MCO', numeric: '492' },
    { value: 'Mongolia', label: 'MN', alpha_three: 'MNG', numeric: '496' },
    { value: 'Montenegro', label: 'ME', alpha_three: 'MNE', numeric: '499' },
    { value: 'Montserrat', label: 'MS', alpha_three: 'MSR', numeric: '500' },
    { value: 'Morocco', label: 'MA', alpha_three: 'MAR', numeric: '504' },
    { value: 'Mozambique', label: 'MZ', alpha_three: 'MOZ', numeric: '508' },
    { value: 'Myanmar', label: 'MM', alpha_three: 'MMR', numeric: '104' },
    { value: 'Namibia', label: 'NA', alpha_three: 'NAM', numeric: '516' },
    { value: 'Nauru', label: 'NR', alpha_three: 'NRU', numeric: '520' },
    { value: 'Nepal', label: 'NP', alpha_three: 'NPL', numeric: '524' },
    {
      value: 'Netherlands (the)',
      label: 'NL',
      alpha_three: 'NLD',
      numeric: '528',
    },
    { value: 'New Caledonia', label: 'NC', alpha_three: 'NCL', numeric: '540' },
    { value: 'New Zealand', label: 'NZ', alpha_three: 'NZL', numeric: '554' },
    { value: 'Nicaragua', label: 'NI', alpha_three: 'NIC', numeric: '558' },
    { value: 'Niger (the)', label: 'NE', alpha_three: 'NER', numeric: '562' },
    { value: 'Nigeria', label: 'NG', alpha_three: 'NGA', numeric: '566' },
    { value: 'Niue', label: 'NU', alpha_three: 'NIU', numeric: '570' },
    { value: 'Norfolk Island', label: 'NF', alpha_three: 'NFK', numeric: '574' },
    {
      value: 'Northern Mariana Islands (the)',
      label: 'MP',
      alpha_three: 'MNP',
      numeric: '580',
    },
    { value: 'Norway', label: 'NO', alpha_three: 'NOR', numeric: '578' },
    { value: 'Oman', label: 'OM', alpha_three: 'OMN', numeric: '512' },
    { value: 'Pakistan', label: 'PK', alpha_three: 'PAK', numeric: '586' },
    { value: 'Palau', label: 'PW', alpha_three: 'PLW', numeric: '585' },
    {
      value: 'Palestine, State of',
      label: 'PS',
      alpha_three: 'PSE',
      numeric: '275',
    },
    { value: 'Panama', label: 'PA', alpha_three: 'PAN', numeric: '591' },
    {
      value: 'Papua New Guinea',
      label: 'PG',
      alpha_three: 'PNG',
      numeric: '598',
    },
    { value: 'Paraguay', label: 'PY', alpha_three: 'PRY', numeric: '600' },
    { value: 'Peru', label: 'PE', alpha_three: 'PER', numeric: '604' },
    {
      value: 'Philippines (the)',
      label: 'PH',
      alpha_three: 'PHL',
      numeric: '608',
    },
    { value: 'Pitcairn', label: 'PN', alpha_three: 'PCN', numeric: '612' },
    { value: 'Poland', label: 'PL', alpha_three: 'POL', numeric: '616' },
    { value: 'Portugal', label: 'PT', alpha_three: 'PRT', numeric: '620' },
    { value: 'Puerto Rico', label: 'PR', alpha_three: 'PRI', numeric: '630' },
    { value: 'Qatar', label: 'QA', alpha_three: 'QAT', numeric: '634' },
    {
      value: 'Republic of North Macedonia',
      label: 'MK',
      alpha_three: 'MKD',
      numeric: '807',
    },
    { value: 'Romania', label: 'RO', alpha_three: 'ROU', numeric: '642' },
    {
      value: 'Russian Federation (the)',
      label: 'RU',
      alpha_three: 'RUS',
      numeric: '643',
    },
    { value: 'Rwanda', label: 'RW', alpha_three: 'RWA', numeric: '646' },
    { value: 'Réunion', label: 'RE', alpha_three: 'REU', numeric: '638' },
    {
      value: 'Saint Barthélemy',
      label: 'BL',
      alpha_three: 'BLM',
      numeric: '652',
    },
    {
      value: 'Saint Helena, Ascension and Tristan da Cunha',
      label: 'SH',
      alpha_three: 'SHN',
      numeric: '654',
    },
    {
      value: 'Saint Kitts and Nevis',
      label: 'KN',
      alpha_three: 'KNA',
      numeric: '659',
    },
    { value: 'Saint Lucia', label: 'LC', alpha_three: 'LCA', numeric: '662' },
    {
      value: 'Saint Martin (French part)',
      label: 'MF',
      alpha_three: 'MAF',
      numeric: '663',
    },
    {
      value: 'Saint Pierre and Miquelon',
      label: 'PM',
      alpha_three: 'SPM',
      numeric: '666',
    },
    {
      value: 'Saint Vincent and the Grenadines',
      label: 'VC',
      alpha_three: 'VCT',
      numeric: '670',
    },
    { value: 'Samoa', label: 'WS', alpha_three: 'WSM', numeric: '882' },
    { value: 'San Marino', label: 'SM', alpha_three: 'SMR', numeric: '674' },
    {
      value: 'Sao Tome and Principe',
      label: 'ST',
      alpha_three: 'STP',
      numeric: '678',
    },
    { value: 'Saudi Arabia', label: 'SA', alpha_three: 'SAU', numeric: '682' },
    { value: 'Senegal', label: 'SN', alpha_three: 'SEN', numeric: '686' },
    { value: 'Serbia', label: 'RS', alpha_three: 'SRB', numeric: '688' },
    { value: 'Seychelles', label: 'SC', alpha_three: 'SYC', numeric: '690' },
    { value: 'Sierra Leone', label: 'SL', alpha_three: 'SLE', numeric: '694' },
    { value: 'Singapore', label: 'SG', alpha_three: 'SGP', numeric: '702' },
    {
      value: 'Sint Maarten (Dutch part)',
      label: 'SX',
      alpha_three: 'SXM',
      numeric: '534',
    },
    { value: 'Slovakia', label: 'SK', alpha_three: 'SVK', numeric: '703' },
    { value: 'Slovenia', label: 'SI', alpha_three: 'SVN', numeric: '705' },
    { value: 'Solomon Islands', label: 'SB', alpha_three: 'SLB', numeric: '090' },
    { value: 'Somalia', label: 'SO', alpha_three: 'SOM', numeric: '706' },
    { value: 'South Africa', label: 'ZA', alpha_three: 'ZAF', numeric: '710' },
    {
      value: 'South Georgia and the South Sandwich Islands',
      label: 'GS',
      alpha_three: 'SGS',
      numeric: '239',
    },
    { value: 'South Sudan', label: 'SS', alpha_three: 'SSD', numeric: '728' },
    { value: 'Spain', label: 'ES', alpha_three: 'ESP', numeric: '724' },
    { value: 'Sri Lanka', label: 'LK', alpha_three: 'LKA', numeric: '144' },
    { value: 'Sudan (the)', label: 'SD', alpha_three: 'SDN', numeric: '729' },
    { value: 'Suriname', label: 'SR', alpha_three: 'SUR', numeric: '740' },
    {
      value: 'Svalbard and Jan Mayen',
      label: 'SJ',
      alpha_three: 'SJM',
      numeric: '744',
    },
    { value: 'Sweden', label: 'SE', alpha_three: 'SWE', numeric: '752' },
    { value: 'Switzerland', label: 'CH', alpha_three: 'CHE', numeric: '756' },
    {
      value: 'Syrian Arab Republic',
      label: 'SY',
      alpha_three: 'SYR',
      numeric: '760',
    },
    {
      value: 'Taiwan (Province of China)',
      label: 'TW',
      alpha_three: 'TWN',
      numeric: '158',
    },
    { value: 'Tajikistan', label: 'TJ', alpha_three: 'TJK', numeric: '762' },
    {
      value: 'Tanzania, United Republic of',
      label: 'TZ',
      alpha_three: 'TZA',
      numeric: '834',
    },
    { value: 'Thailand', label: 'TH', alpha_three: 'THA', numeric: '764' },
    { value: 'Timor-Leste', label: 'TL', alpha_three: 'TLS', numeric: '626' },
    { value: 'Togo', label: 'TG', alpha_three: 'TGO', numeric: '768' },
    { value: 'Tokelau', label: 'TK', alpha_three: 'TKL', numeric: '772' },
    { value: 'Tonga', label: 'TO', alpha_three: 'TON', numeric: '776' },
    {
      value: 'Trinidad and Tobago',
      label: 'TT',
      alpha_three: 'TTO',
      numeric: '780',
    },
    { value: 'Tunisia', label: 'TN', alpha_three: 'TUN', numeric: '788' },
    { value: 'Turkey', label: 'TR', alpha_three: 'TUR', numeric: '792' },
    { value: 'Turkmenistan', label: 'TM', alpha_three: 'TKM', numeric: '795' },
    {
      value: 'Turks and Caicos Islands (the)',
      label: 'TC',
      alpha_three: 'TCA',
      numeric: '796',
    },
    { value: 'Tuvalu', label: 'TV', alpha_three: 'TUV', numeric: '798' },
    { value: 'Uganda', label: 'UG', alpha_three: 'UGA', numeric: '800' },
    { value: 'Ukraine', label: 'UA', alpha_three: 'UKR', numeric: '804' },
    {
      value: 'United Arab Emirates (the)',
      label: 'AE',
      alpha_three: 'ARE',
      numeric: '784',
    },
    {
      value: 'United Kingdom of Great Britain and Northern Ireland (the)',
      label: 'GB',
      alpha_three: 'GBR',
      numeric: '826',
    },
    {
      value: 'United States Minor Outlying Islands (the)',
      label: 'UM',
      alpha_three: 'UMI',
      numeric: '581',
    },
    {
      value: 'United States of America (the)',
      label: 'US',
      alpha_three: 'USA',
      numeric: '840',
    },
    { value: 'Uruguay', label: 'UY', alpha_three: 'URY', numeric: '858' },
    { value: 'Uzbekistan', label: 'UZ', alpha_three: 'UZB', numeric: '860' },
    { value: 'Vanuatu', label: 'VU', alpha_three: 'VUT', numeric: '548' },
    {
      value: 'Venezuela (Bolivarian Republic of)',
      label: 'VE',
      alpha_three: 'VEN',
      numeric: '862',
    },
    { value: 'Viet Nam', label: 'VN', alpha_three: 'VNM', numeric: '704' },
    {
      value: 'Virgin Islands (British)',
      label: 'VG',
      alpha_three: 'VGB',
      numeric: '092',
    },
    {
      value: 'Virgin Islands (U.S.)',
      label: 'VI',
      alpha_three: 'VIR',
      numeric: '850',
    },
    {
      value: 'Wallis and Futuna',
      label: 'WF',
      alpha_three: 'WLF',
      numeric: '876',
    },
    { value: 'Western Sahara', label: 'EH', alpha_three: 'ESH', numeric: '732' },
    { value: 'Yemen', label: 'YE', alpha_three: 'YEM', numeric: '887' },
    { value: 'Zambia', label: 'ZM', alpha_three: 'ZMB', numeric: '894' },
    { value: 'Zimbabwe', label: 'ZW', alpha_three: 'ZWE', numeric: '716' },
    { value: 'Åland Islands', label: 'AX', alpha_three: 'ALA', numeric: '248' },
  ];